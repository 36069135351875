import React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from './index.module.css';
import PrimaryButton from "../components/primary-button";
import Img from "gatsby-image";

const IndexPage = ({data}) => (
  <Layout>
    <SEO
      title="elDiario.es con la pequeña empresa y las cooperativas"
      description="Potencia ahora tu marca y aumenta tus ventas. Contrata tu campaña de publicidad en formatos banner billboard (980x250 px) y doble robapáginas (300x600 px) 600 euros. ¡SOLO POR TIEMPO LIMITADO!"
      titleTemplate="%s"
    />
    <Img
      className={styles.mainImage}
      fluid={[
        data.mobileImage.childImageSharp.fluid,
        {
          ...data.desktopImage.childImageSharp.fluid,
          media: `(min-width: 1440px)`,
        },
        {
          ...data.ipaqImage.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]}/>
    <article className={styles.article}>
      <section>
        <h1><strong>elDiario.es</strong> con la pequeña empresa y las cooperativas</h1>

        <p>
          En estos momentos tan complicados, queremos ofrecerte unas condiciones muy especiales para anunciarte en
          elDiario.es. Porque son las pequeñas empresas y cooperativas como la tuya las que hacen mejor nuestro día a
          día, con vuestro trabajo y esfuerzo diario por mejorar la vida de todos nosotros.
        </p>

        <p style={{
          fontWeight: '500'
        }}>
          Potencia ahora tu marca y aumenta tus ventas.
          <br/>Contrata tu campaña de publicidad en formatos banner billboard (980x250 px) y doble robapáginas (300x600
          px) desde 600 euros.
          <br/>¡SOLO POR TIEMPO LIMITADO!
        </p>

        <p>
          <PrimaryButton to="/compra/">COMPRAR AHORA</PrimaryButton>
        </p>
        <p>
          <Link to="/mas-informacion/">Solicitar más información </Link>
        </p>
      </section>
      <section>
        <p className={styles.highlight}>elDiario.es con la pequeña empresa y las cooperativas</p>

        <p>
          Las pequeñas y medianas empresas, junto con las cooperativas, sostienen el tejido productivo de nuestro país.
          Durante esta crisis sanitaria, social y económica sin precedentes han sido clave para el abastecimiento y han
          demostrado, una vez más, ser esenciales para el avance de la sociedad.
        </p>
        <p>
          Si has logrado que tus productos y servicios lleguen a tus clientes a través del comercio electrónico, tenemos
          una
          propuesta para ti. Desde ahora, y por tiempo limitado, abrimos la posibilidad de comprar publicidad en
          elDiario.es
          para acceder a nuestra audiencia de más de 17 millones de usuarios únicos.
        </p>
        <p>
          Esta campaña está dirigida a todos aquellas pequeñas, medianas empresas y cooperativas que normalmente no se
          lo
          pueden permitir, y que utilizan redes sociales y buscadores para hacer su publicidad: Google, Facebook o
          Instagram. Cumpliendo unos requisitos mínimos podrás acceder a una tarifa especial y adaptada a tus
          necesidades.
        </p>
        <p>
          En estos momentos difíciles, lo que proponemos es luchar juntos para salir adelante. Al hacer publicidad en
          elDiario.es conseguirás un triple objetivo: incrementar tus ventas, mejorar tu imagen de marca y contribuir a
          la
          existencia del periodismo independiente que hacemos en elDiario.es. Además, te ofreceremos información útil de
          actualidad relacionada con tu sector.
        </p>

        <div style={{textAlign: 'center'}}>
          <p className={styles.highlight2}>
            Desde tan sólo 600
            euros puedes anunciarte
            en nuestro periódico a
            través de nuestros
            múltiples productos
            publicitarios: banners,
            vídeos y contenidos
          </p>
          <p>
            <PrimaryButton to="/compra/">COMPRAR AHORA</PrimaryButton>
          </p>
          <p>
            <Link to="/mas-informacion/">Solicitar más información </Link>
          </p>
        </div>
      </section>
    </article>
  </Layout>
)

export default IndexPage

export const query = graphql`
    query {
      mobileImage: file(relativePath: { eq: "mobiles-glasses.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ipaqImage: file(relativePath: { eq: "mobiles-glasses-ipaq.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "mobiles-glasses-pc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
